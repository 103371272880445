import {Component, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from "../../app/translate.service";
import * as $ from 'jquery';
declare var $: any;

const SCRIPTS = [
  '../../assets/amplify/js/main.js',
  '../../assets/amplify/js/jquery-3.3.1.min.js',
  '../../assets/amplify/js/jquery-migrate-3.0.1.min.js',
  '../../assets/amplify/js/jquery-ui.js',
  '../../assets/amplify/js/popper.min.js',
  '../../assets/amplify/js/bootstrap.min.js',
  '../../assets/amplify/js/owl.carousel.min.js',
  '../../assets/amplify/js/jquery.stellar.min.js',
  '../../assets/amplify/js/jquery.countdown.min.js',
  '../../assets/amplify/js/bootstrap-datepicker.min.js',
  '../../assets/amplify/js/jquery.easing.1.3.js',
  '../../assets/amplify/js/aos.js',
  '../../assets/amplify/js/jquery.fancybox.min.js',
  '../../assets/amplify/js/jquery.sticky.js'
];

@Component({
  selector: 'app-amplify',
  templateUrl: './amplify.component.html',
  styleUrls: ['./amplify.component.scss']
})
export class AmplifyComponent implements OnInit {

  constructor(private translateService: TranslateService, private renderer: Renderer2) { }

  ngOnInit() {
    $('*').on('mouseover mouseout', function(e) {
      $(this).toggleClass('hovering', e.type === 'mouseover');
      e.stopPropagation();
    });
    this.translateService.setPageId('bA3159');
    SCRIPTS.forEach(script => this.loadScript(script));
  }

  loadScript(url: string) {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = false;
    this.renderer.appendChild(document.body, script);
  }

}
