import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatDialog} from "@angular/material";
import {FeedbackDialogComponent} from "../feedback-dialog/feedback-dialog.component";

@Component({
  selector: 'app-feedback-snackbar',
  templateUrl: './feedback-snackbar.component.html',
  styleUrls: ['./feedback-snackbar.component.scss']
})
export class FeedbackSnackbarComponent implements OnInit {

  commands: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private matDialog: MatDialog) {
    this.commands = data.commands;
  }

  ngOnInit() {
  }

  sendFeedback() {
    this.data.stopCallback();
    this.matDialog.open(FeedbackDialogComponent, {data: {commands: this.commands}});
  }

}
