import {Component, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from "../../app/translate.service";
import * as $ from 'jquery';

declare var $: any;

const SCRIPTS = [
  '../../assets/kindle/js/app.js',
  '../../assets/kindle/js/custom.js',
  '../../assets/kindle/js/counter.js',
  'https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js',
  '../../assets/kindle/js/bootstrap.min.js',
  'https://oss.maxcdn.com/html5shiv/3.7.2/html5shiv.min.js',
  'https://oss.maxcdn.com/respond/1.4.2/respond.min.js',
  '../../assets/kindle/js/slick.min.js'
];

@Component({
  selector: 'app-kindle',
  templateUrl: './kindle.component.html',
  styleUrls: ['./kindle.component.scss']
})
export class KindleComponent implements OnInit {

  constructor(private translateService: TranslateService, private renderer: Renderer2) {
  }

  ngOnInit() {
    $('*').on('mouseover mouseout', function (e) {
      $(this).toggleClass('hovering', e.type === 'mouseover');
      e.stopPropagation();
    });
    this.translateService.setPageId('e34a20');
    SCRIPTS.forEach(script => this.loadScript(script));
  }

  loadScript(url: string) {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = false;
    this.renderer.appendChild(document.body, script);
  }

}
