import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TemplatesComponent} from './templates/templates.component';
import {HomeComponent} from './home/home.component';
import {GilbertComponent} from '../templates/gilbert/gilbert.component';
import {AmplifyComponent} from '../templates/amplify/amplify.component';
import {PublishedSiteComponent} from "./published-site/published-site.component";
import {ExternalSiteComponent} from "./external-site/external-site.component";
import {KindleComponent} from "../templates/kindle/kindle.component";
import {AppfastComponent} from "../templates/appfast/appfast.component";
import {ZiggyComponent} from "../templates/ziggy/ziggy.component";
import {SolutionComponent} from "../templates/solution/solution.component";
import {JoinComponent} from "./join/join.component";

const routes: Routes = [
  {component: TemplatesComponent, path: 'templates'},
  {component: GilbertComponent, path: 'edit/aC3940'},
  {component: AmplifyComponent, path: 'edit/bA3159'},
  {component: KindleComponent, path: 'edit/e34a20'},
  {component: AppfastComponent, path: 'edit/q3T36s0'},
  {component: ZiggyComponent, path: 'edit/se283s2'},
  {component: SolutionComponent, path: 'edit/v38w24'},
  {component: ExternalSiteComponent, path: 'edit/:site'},
  {component: PublishedSiteComponent, path: ':id'},
  {component: JoinComponent, path: 'join/:id'}, //land here, pick a name, redirect to the appropriate component
  {component: HomeComponent, path: '', pathMatch: 'full'}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
