// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  translate: 'https://us-central1-monolog-a6d87.cloudfunctions.net/translateSpeech',
  firebaseConfig: {
    apiKey: 'AIzaSyAFZFvfUBj0uH8nh2TceNflpYMspVM8C3c',
    authDomain: 'monolog.io',
    databaseURL: 'https://monolog-a6d87.firebaseio.com',
    projectId: 'monolog-a6d87',
    storageBucket: 'monolog-a6d87.appspot.com',
    messagingSenderId: '737369838781',
    appId: '1:737369838781:web:12160ef343dfb8d4db13d4',
    measurementId: 'G-476HT4KJR6'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
