import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DesignOption} from './models/design-option.model';
import {EntryChange, LogEntry} from './models/log-entry.model';
import {MatSnackBar} from '@angular/material';
import {FeedbackSnackbarComponent} from './feedback-snackbar/feedback-snackbar.component';
import {AngularFireDatabase} from "@angular/fire/database";
import {AngularFireAuth} from "@angular/fire/auth";
import {take} from "rxjs/operators";
import {interactions} from "./interactions";
import {AngularFirestore} from "@angular/fire/firestore";
import * as chromiumCopyCssPath from 'cssman';

declare var webkitSpeechRecognition: any;
declare var SpeechRecognition: any;
declare var gtag: any;
const copySelector = (element) => chromiumCopyCssPath(element);


const groupChars = ['A', 'Q', 'g', 'w', 'B', 'R', 'h', 'x', 'C', 'S', 'i', 'y', 'D', 'T', 'j', 'z', 'E', 'U', 'k', '0', 'F', 'V', 'l', '1', 'G', 'W', 'm', '2', 'H', 'X', 'n', '3', 'I', 'Y', 'o', '4', 'J', 'Z', 'p', '5', 'K', 'a', 'q', '6', 'L', 'b', 'r', '7', 'M', 'c', 's', '8', 'N', 'd', 't', '9', 'O', 'e', 'u', 'P', 'f', 'v'];
const defaultLinkLength = 4;

@Injectable({
  providedIn: 'root'
})
export class TranslateService {


  constructor(private snackbar: MatSnackBar, private angularFirestore: AngularFirestore, private angularFireDatabase: AngularFireDatabase, private afAuth: AngularFireAuth) {
  }

  private _commands$: BehaviorSubject<string> = new BehaviorSubject('');
  command$: Observable<string> = this._commands$.asObservable();

  private _log$: BehaviorSubject<LogEntry[]> = new BehaviorSubject([]);
  log$: Observable<LogEntry[]> = this._log$.asObservable();

  private _selecting$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selecting$: Observable<boolean> = this._selecting$.asObservable();

  private _describing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  describing$: Observable<boolean> = this._describing$.asObservable();

  private _hidden$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hidden$: Observable<boolean> = this._hidden$.asObservable();

  private _options$: BehaviorSubject<DesignOption> = new BehaviorSubject(null);
  options$: Observable<DesignOption> = this._options$.asObservable();

  valMap = [];

  selectedElement;
  voice: any;
  recording = false;
  link;
  finalTranscript = '';
  pageId = '';
  viewContainerRef;
  lockLog = false;
  displayName;

  materialColors = {
    Red: ['rgb(255, 235, 238)', 'rgb(255, 205, 210)', 'rgb(239, 154, 154)', 'rgb(229, 115, 115)', 'rgb(239, 83, 80)', 'rgb(244, 67, 54)', 'rgb(229, 57, 53)', 'rgb(211, 47, 47)', 'rgb(198, 40, 40)', 'rgb(183, 28, 28)', 'rgb(255, 138, 128)', 'rgb(255, 82, 82)', 'rgb(255, 23, 68)', 'rgb(213, 0, 0)'],
    Pink: ['rgb(252, 228, 236)', 'rgb(248, 187, 208)', 'rgb(244, 143, 177)', 'rgb(240, 98, 146)', 'rgb(236, 64, 122)', 'rgb(233, 30, 99)', 'rgb(216, 27, 96)', 'rgb(194, 24, 91)', 'rgb(173, 20, 87)', 'rgb(136, 14, 79)', 'rgb(255, 128, 171)', 'rgb(255, 64, 129)', 'rgb(245, 0, 87)', 'rgb(197, 17, 98)'],
    Purple: ['rgb(243, 229, 245)', 'rgb(225, 190, 231)', 'rgb(206, 147, 216)', 'rgb(186, 104, 200)', 'rgb(171, 71, 188)', 'rgb(156, 39, 176)', 'rgb(142, 36, 170)', 'rgb(123, 31, 162)', 'rgb(106, 27, 154)', 'rgb(74, 20, 140)', 'rgb(234, 128, 252)', 'rgb(224, 64, 251)', 'rgb(213, 0, 249)', 'rgb(170, 0, 255)'],
    'Deep-purple': ['rgb(237, 231, 246)', 'rgb(209, 196, 233)', 'rgb(179, 157, 219)', 'rgb(149, 117, 205)', 'rgb(126, 87, 194)', 'rgb(103, 58, 183)', 'rgb(94, 53, 177)', 'rgb(81, 45, 168)', 'rgb(69, 39, 160)', 'rgb(49, 27, 146)', 'rgb(179, 136, 255)', 'rgb(124, 77, 255)', 'rgb(101, 31, 255)', 'rgb(98, 0, 234)'],
    Indigo: ['rgb(232, 234, 246)', 'rgb(197, 202, 233)', 'rgb(159, 168, 218)', 'rgb(121, 134, 203)', 'rgb(92, 107, 192)', 'rgb(63, 81, 181)', 'rgb(57, 73, 171)', 'rgb(48, 63, 159)', 'rgb(40, 53, 147)', 'rgb(26, 35, 126)', 'rgb(140, 158, 255)', 'rgb(83, 109, 254)', 'rgb(61, 90, 254)', 'rgb(48, 79, 254)'],
    Blue: ['rgb(227, 242, 253)', 'rgb(187, 222, 251)', 'rgb(144, 202, 249)', 'rgb(100, 181, 246)', 'rgb(66, 165, 245)', 'rgb(33, 150, 243)', 'rgb(30, 136, 229)', 'rgb(25, 118, 210)', 'rgb(21, 101, 192)', 'rgb(13, 71, 161)', 'rgb(130, 177, 255)', 'rgb(68, 138, 255)', 'rgb(41, 121, 255)', 'rgb(41, 98, 255)'],
    'Light-blue': ['rgb(225, 245, 254)', 'rgb(179, 229, 252)', 'rgb(129, 212, 250)', 'rgb(79, 195, 247)', 'rgb(41, 182, 246)', 'rgb(3, 169, 244)', 'rgb(3, 155, 229)', 'rgb(2, 136, 209)', 'rgb(2, 119, 189)', 'rgb(1, 87, 155)', 'rgb(128, 216, 255)', 'rgb(64, 196, 255)', 'rgb(0, 176, 255)', 'rgb(0, 145, 234)'],
    Cyan: ['rgb(224, 247, 250)', 'rgb(178, 235, 242)', 'rgb(128, 222, 234)', 'rgb(77, 208, 225)', 'rgb(38, 198, 218)', 'rgb(0, 188, 212)', 'rgb(0, 172, 193)', 'rgb(0, 151, 167)', 'rgb(0, 131, 143)', 'rgb(0, 96, 100)', 'rgb(132, 255, 255)', 'rgb(24, 255, 255)', 'rgb(0, 229, 255)', 'rgb(0, 184, 212)'],
    Teal: ['rgb(224, 242, 241)', 'rgb(178, 223, 219)', 'rgb(128, 203, 196)', 'rgb(77, 182, 172)', 'rgb(38, 166, 154)', 'rgb(0, 150, 136)', 'rgb(0, 137, 123)', 'rgb(0, 121, 107)', 'rgb(0, 105, 92)', 'rgb(0, 77, 64)', 'rgb(167, 255, 235)', 'rgb(100, 255, 218)', 'rgb(29, 233, 182)', 'rgb(0, 191, 165)'],
    Green: ['rgb(232, 245, 233)', 'rgb(200, 230, 201)', 'rgb(165, 214, 167)', 'rgb(129, 199, 132)', 'rgb(102, 187, 106)', 'rgb(76, 175, 80)', 'rgb(67, 160, 71)', 'rgb(56, 142, 60)', 'rgb(46, 125, 50)', 'rgb(27, 94, 32)', 'rgb(185, 246, 202)', 'rgb(105, 240, 174)', 'rgb(0, 230, 118)', 'rgb(0, 200, 83)'],
    'Light-green': ['rgb(241, 248, 233)', 'rgb(220, 237, 200)', 'rgb(197, 225, 165)', 'rgb(174, 213, 129)', 'rgb(156, 204, 101)', 'rgb(139, 195, 74)', 'rgb(124, 179, 66)', 'rgb(104, 159, 56)', 'rgb(85, 139, 47)', 'rgb(51, 105, 30)', 'rgb(204, 255, 144)', 'rgb(178, 255, 89)', 'rgb(118, 255, 3)', 'rgb(100, 221, 23)'],
    Lime: ['rgb(249, 251, 231)', 'rgb(240, 244, 195)', 'rgb(230, 238, 156)', 'rgb(220, 231, 117)', 'rgb(212, 225, 87)', 'rgb(205, 220, 57)', 'rgb(192, 202, 51)', 'rgb(175, 180, 43)', 'rgb(158, 157, 36)', 'rgb(130, 119, 23)', 'rgb(244, 255, 129)', 'rgb(238, 255, 65)', 'rgb(198, 255, 0)', 'rgb(174, 234, 0)'],
    Yellow: ['rgb(255, 253, 231)', 'rgb(255, 249, 196)', 'rgb(255, 245, 157)', 'rgb(255, 241, 118)', 'rgb(255, 238, 88)', 'rgb(255, 235, 59)', 'rgb(253, 216, 53)', 'rgb(251, 192, 45)', 'rgb(249, 168, 37)', 'rgb(245, 127, 23)', 'rgb(255, 255, 141)', 'rgb(255, 255, 0)', 'rgb(255, 234, 0)', 'rgb(255, 214, 0)'],
    Amber: ['rgb(255, 248, 225)', 'rgb(255, 236, 179)', 'rgb(255, 224, 130)', 'rgb(255, 213, 79)', 'rgb(255, 202, 40)', 'rgb(255, 193, 7)', 'rgb(255, 179, 0)', 'rgb(255, 160, 0)', 'rgb(255, 143, 0)', 'rgb(255, 111, 0)', 'rgb(255, 229, 127)', 'rgb(255, 215, 64)', 'rgb(255, 196, 0)', 'rgb(255, 171, 0)'],
    Orange: ['rgb(255, 243, 224)', 'rgb(255, 224, 178)', 'rgb(255, 204, 128)', 'rgb(255, 183, 77)', 'rgb(255, 167, 38)', 'rgb(255, 152, 0)', 'rgb(251, 140, 0)', 'rgb(245, 124, 0)', 'rgb(239, 108, 0)', 'rgb(230, 81, 0)', 'rgb(255, 209, 128)', 'rgb(255, 171, 64)', 'rgb(255, 145, 0)', 'rgb(255, 109, 0)'],
    'Deep-orange': ['rgb(251, 233, 231)', 'rgb(255, 204, 188)', 'rgb(255, 171, 145)', 'rgb(255, 138, 101)', 'rgb(255, 112, 67)', 'rgb(255, 87, 34)', 'rgb(244, 81, 30)', 'rgb(230, 74, 25)', 'rgb(216, 67, 21)', 'rgb(191, 54, 12)', 'rgb(255, 158, 128)', 'rgb(255, 110, 64)', 'rgb(255, 61, 0)', 'rgb(221, 44, 0)'],
    Brown: ['rgb(239, 235, 233)', 'rgb(215, 204, 200)', 'rgb(188, 170, 164)', 'rgb(161, 136, 127)', 'rgb(141, 110, 99)', 'rgb(121, 85, 72)', 'rgb(109, 76, 65)', 'rgb(93, 64, 55)', 'rgb(78, 52, 46)', 'rgb(62, 39, 35)'],
    Grey: ['rgb(250, 250, 250)', 'rgb(245, 245, 245)', 'rgb(238, 238, 238)', 'rgb(224, 224, 224)', 'rgb(189, 189, 189)', 'rgb(158, 158, 158)', 'rgb(117, 117, 117)', 'rgb(97, 97, 97)', 'rgb(66, 66, 66)', 'rgb(33, 33, 33)'],
    'Blue-grey': ['rgb(236, 239, 241)', 'rgb(207, 216, 220)', 'rgb(176, 190, 197)', 'rgb(144, 164, 174)', 'rgb(120, 144, 156)', 'rgb(96, 125, 139)', 'rgb(84, 110, 122)', 'rgb(69, 90, 100)', 'rgb(55, 71, 79)', 'rgb(38, 50, 56)']
  };
  increasingModifiers = ['way', 'far', 'much', 'ass', 'fuck', 'shit', 'ton'];
  decreasingModifiers = ['bit', 'smidge', 'tad', 'little', 'pinch'];

  setCommands(newCommands) {
    this._commands$.next(newCommands);
  }

  toggleSelecting() {
    const newValue = !this._selecting$.value;
    this._selecting$.next(newValue);

    if (newValue === true) {
      this.toggleRecording();
    } else {
      this.clearSelectedElement();
    }
  }

  toggleHide() {
    this._hidden$.next(!this._hidden$.value);
  }

  toggle(index?) {
    const indexOfLastUnStruckEntry = (l): number => l && l.length > 0 ? l.reverse().findIndex(e => e && !e.struck) : -1;
    const log = [...this._log$.value];
    if (index === undefined)
      index = indexOfLastUnStruckEntry(log);
    if (index === -1)
      return;
    log[index].struck = !log[index].struck;
    this.applyEntry(log[index], log[index].struck);
    this.saveLog(log);
  }

  redo() {
    const indexOfLastStruckEntry = (l): number => l && l.length > 0 ? l.reverse().findIndex(e => e && e.struck === true) : -1;
    const log = [...this._log$.value];
    const index = (log.length - 1) - indexOfLastStruckEntry(log);
    this.toggle(index);
  }

  dismissOptions() {
    this._options$.next(null);
  }

  selectElement(element: Element) {
    if (!this._selecting$.value || element.classList.contains('not-selectable')) {
      return;
    }
    element.classList.add('selected');
    this.selectedElement = element;
    this._selecting$.next(false);
    this._describing$.next(true);
  }

  addLog(commands, changes, actor?) {
    if (this.lockLog)
      return;
    actor = actor || this.getDisplayName();
    const log = this._log$.value;
    const fullPath = copySelector(this.selectedElement) ? copySelector(this.selectedElement).replace('.hovering', '').replace('.selected', '') : copySelector(this.selectedElement);
    log.push(new LogEntry(actor, new Date().toLocaleTimeString(), this.capitalize(commands), fullPath, null, null, null, changes));
    this._log$.next(log);
    this.saveLog(log);
  }

  addSingletonLog(commands, property, oldVal, newVal, element?, actor?) {
    if (this.lockLog)
      return;
    actor = actor || this.getDisplayName();
    const log = this._log$.value;
    const fullPath = copySelector(element || this.selectedElement) ? copySelector(element || this.selectedElement).replace('.hovering', '').replace('.selected', '') : copySelector(element || this.selectedElement);
    log.push(new LogEntry(actor, new Date().toLocaleTimeString(), this.capitalize(commands), fullPath, property, oldVal, newVal, null));
    this._log$.next(log);
    this.saveLog(log);
  }

  addInteractionLog(commands, element, interaction, actor?) {
    if (this.lockLog)
      return;
    actor = actor || this.getDisplayName();
    const log = this._log$.value;
    const fullPath = copySelector(element || this.selectedElement) ? copySelector(element || this.selectedElement).replace('.hovering', '').replace('.selected', '') : copySelector(element || this.selectedElement);
    log.push(new LogEntry(actor, new Date().toLocaleTimeString(), this.capitalize(commands), fullPath, null, null, null, null, interaction));
    this._log$.next(log);
    this.saveLog(log);
  }

  addChangelessLog(commands, actor?) {
    if (this.lockLog)
      return;
    actor = actor || this.getDisplayName();
    const log = this._log$.value;
    log.push(new LogEntry(actor, new Date().toLocaleTimeString(), this.capitalize(commands), '', '', '', '', []));
    this._log$.next(log);
    this.saveLog(log);
  }

  loadLog(log: LogEntry[]) {
    this._log$.next(log);
    log.forEach(entry => {
      if (!entry.struck)
        this.applyEntry(entry);
    });
  }

  private applyEntry(entry, reverse?) {
    if (entry.interaction) { // FIXME: disgustang
      this.lockLog = true;
      const interaction = interactions[entry.interaction]; // FIXME: Unreliable with Firebase interactions
      const swap = this.selectedElement;
      this.selectedElement = document.querySelector(entry.selector);


      this.applyInteraction(interaction, entry.commands);

      this.selectedElement = swap;
      this.lockLog = false;
    }
    if (entry.changes) {
      entry.changes.forEach(change => {
        const element = document.querySelector(change.alternateSelector || entry.selector);
        if (element) {
          if (change.property === 'innerHTML') {
            // @ts-ignore
            element.innerHTML = reverse ? change.oldValue : change.newValue;
          } else if (change.property === 'src') {
            // @ts-ignore
            element.src = reverse ? change.oldValue : change.newValue;
          } else if (change.property === 'font-size') {
            // @ts-ignore
            element.style.fontSize = reverse ? change.oldValue : change.newValue;
          } else {

            if ((change.property === 'height' || change.property === 'width') && reverse && typeof change.oldValue === "number") {
              change.oldValue = String(change.oldValue) + 'px';
            }
            // @ts-ignore
            element.style[change.property] = reverse ? change.oldValue : change.newValue;
          }
        }
      });
    }
  }

  emptyLog() {
    this._log$.next([]);
  }

  saveLog(log) {
    this.afAuth.authState.pipe(
      take(1),
    ).subscribe(user => {
      if (user) {
        this.angularFireDatabase.database.ref(`/sites/${user.uid}/${this.pageId}`).update({log, link: this.link});
      }
    });
  }

  setDisplayName(name) {
    this.displayName = name;
    localStorage.setItem('name', name);
  }

  getDisplayName() {
    return this.displayName || 'You';
  }

  setPageId(id) {
    this.pageId = id;
    this.afAuth.authState.pipe(
      take(1),
    ).subscribe(user => {
      if (user) {
        this.angularFireDatabase.database.ref(`/sites/${user.uid}/${id}`).once('value', snapshot => {
          if (snapshot.exists()) {
            this.loadLog(snapshot.val().log);
            if (snapshot.val().link && snapshot.val().link.length === 4) {
              this.link = snapshot.val().link;
            } else {
              //EVENT: website setup
              gtag('event', 'website-setup', {
                'event_category': 'Onboarding',
                'event_label': 'Template selected and new website setup'
              });
              this.link = this.generateNewLink();
              this.angularFireDatabase.database.ref(`/links/${this.link}`).update({site: this.pageId, user: user.uid});
            }
          } else {
            this.link = this.generateNewLink();
            this.angularFireDatabase.database.ref(`/links/${this.link}`).update({site: this.pageId, user: user.uid});
          }
        });
      }
    });
  }

  interpretCommands() {
    if (!(this._describing$.value || this._selecting$) || !this._commands$.value) {
      return;
    }
    let commands = this._commands$.value.toLowerCase();
    this._commands$.next('');
    this.finalTranscript = '';


    let parsedCommands = commands;
    ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'].forEach((num, index) => {
      parsedCommands = parsedCommands.replace(num, String(index + 1));
    });
    commands = parsedCommands;

    const interaction = this.getMatchingInteraction(commands);
    this.applyAndAnalyzeInteraction(interaction, commands);

  }

  private applyAndAnalyzeInteraction(interaction, commands) {
    const interactionResult = this.applyInteraction(interaction, commands);

    if (interactionResult === null) {
      gtag('event', 'command-not-understood', {
        'event_category': 'Interactions',
        'event_label': 'Command was not understood'
      });

      this.angularFirestore.collection('missedCommands').add({
        command: commands
      });

      this.snackbar.openFromComponent(FeedbackSnackbarComponent, {
        duration: 5000,
        data: {commands, stopCallback: () => this.fullStop()}
      });
      return;
    }

    gtag('event', interactionResult.interactionName, {
      'event_category': 'Interaction Details',
      'event_label': `${interactionResult.oldValue} -> ${interactionResult.newValue} ${interactionResult.modifiers ? 'Modified by ' + interactionResult.modifiers : ''}`
    });

    //EVENT: website updated
    gtag('event', 'website-updated', {
      'event_category': 'Interactions',
      'event_label': 'Website Updated'
    });

    if (!sessionStorage.getItem('firstInteraction')) {
      sessionStorage.setItem('firstInteraction', '1');
      gtag('event', 'first-interaction', {
        'event_category': 'Interactions',
        'event_label': 'First interaction in this session'
      });
    }

    if (sessionStorage.getItem('firstInteraction') && !sessionStorage.getItem('secondInteraction')) {
      sessionStorage.setItem('secondInteraction', '1');
      gtag('event', 'second-interaction', {
        'event_category': 'Interactions',
        'event_label': 'Second interaction in this session'
      });
    }
  }

  private applyInteraction(interaction, commands) {
    // tslint:disable-next-line:no-eval
    const interactionResult = eval(interaction.action(this, this.getParamsMap(interaction, commands)));
    return interactionResult;
  }

  getMatchingInteraction(commands) {
    let matchingWord: string;
    const commandsMatch = (interactionCloud) => interactionCloud.some(e => {
      const result = commands.toLowerCase().includes(e.toLowerCase());
      if (result) {
        matchingWord = e;
      }
      return result;
    });

    const sortedKeys = Object.keys(interactions).sort((a, b) => interactions[a].order - interactions[b].order);
    for (let i = 0; i < sortedKeys.length; i++) {
      const interaction = interactions[sortedKeys[i]];
      if (commandsMatch(interaction.cloud)) {
        return interaction;
      }
    }
    return null;
  }

  getMatchingWord(interaction, commands) {
    return interaction.cloud.find(term => commands.toLowerCase().includes(term.toLowerCase()));
  }

  getModifyingWord(commands) {
    const modifiers: string[] = [...this.increasingModifiers, ...this.decreasingModifiers, 'left', 'right', 'center', 'centre'];
    return modifiers.find(mod => commands.toLowerCase().includes(mod));
  }

  getParamsMap = (interaction, commands) => {
    const matchingWord = this.getMatchingWord(interaction, commands);
    const modifier = this.getModifyingWord(commands);
    return {
      commands,
      matchingWord,
      modifier,
      document
    };
  };

  toggleRecording() {
    this.finalTranscript = '';
    if (!('webkitSpeechRecognition' in window)) {
      alert('Your browser is missing essential features for Monolog. Please use Chrome.');
      this.recording = false;
    }

    if (!this.recording) {
      this.recording = true;
      this.voice = new webkitSpeechRecognition();
      this.voice.continuous = true;
      this.voice.interimresults = true;
      this.voice.lang = 'en-CA'; // make this selectable
      this.voice.start();

      this.voice.onresult = event => {
        let interim_transcript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.finalTranscript += event.results[i][0].transcript;
            this.setCommands(interim_transcript);
          } else {
            interim_transcript += event.results[i][0].transcript;
            this.setCommands(interim_transcript);
          }
        }
        this.finalTranscript = this.capitalize(this.finalTranscript);
        this.setCommands(this.finalTranscript);
      };

      this.voice.onspeechend = e => {
        this.recording = false;
        if (window.navigator.userAgent.indexOf('Android') == -1) {
          this.fullStop();
        } else {
          this.voice.start();
        }
      };
      this.voice.onerror = e => {
        this.recording = false;

        this.fullStop();
      };
    } else {
      if (this.voice) {
        this.recording = false;
        this.voice.stop();
      }
    }
  }


  capitalize(s) {
    return s.replace(/\S/, (m) => {
      return m.toUpperCase();
    });
  }


  generateNewColorSchemes(amount) {
    this._options$.next(new DesignOption('colors', this.getColorSchemes(amount)));
  }

  updateText(newText) {
    const commands = `*Change text*`;
    const oldVal = this.selectedElement.innerHTML;
    this.selectedElement.innerHTML = newText;
    this.addSingletonLog(commands, 'innerHTML', oldVal, newText, null, this.getDisplayName());
    this.clearSelectedElement();
  }

  updateLink(newLocation) {
    const commands = `*Change link*`;
    const oldVal = this.selectedElement.href;
    this.selectedElement.href = newLocation;
    this.addSingletonLog(commands, 'href', oldVal, newLocation, null, this.getDisplayName());
    this.clearSelectedElement();
  }

  applyColorPalette(option) {
    const commands = `*Apply ${option.primaryName}/${option.secondaryName} palette*`;
    const changes: EntryChange[] = [];
    const background = document.getElementsByTagName('html')[0];
    const oldBgColor = window.getComputedStyle(background, null).getPropertyValue('background-color');
    background.style.backgroundColor = option.primary;

    changes.push(new EntryChange('background-color', oldBgColor, option.primary, copySelector(background)));
    // @ts-ignore
    const headers = [...document.getElementsByTagName('h1'), ...document.getElementsByTagName('h2'), ...document.getElementsByTagName('h3'), ...document.getElementsByTagName('h4'), document.getElementsByTagName('h5'), ...document.getElementsByTagName('h6')];
    headers
      .filter(e => !(e instanceof HTMLCollection))
      .filter((e: HTMLElement) => !e.classList.contains('not-selectable'))
      .forEach(element => {
        const oldVal = window.getComputedStyle(element, null).getPropertyValue('color');
        element.style.color = option.secondary;
        changes.push(new EntryChange('color', oldVal, option.secondary, copySelector(element)));
      });

    this.addLog(commands, changes, this.getDisplayName());
  }

  selectImage(option) {
    const commands = `*Select an image*`;

    const originalHeight = window.getComputedStyle(this.selectedElement, null).getPropertyValue('height');
    const originalWidth = window.getComputedStyle(this.selectedElement, null).getPropertyValue('width');

    const options = Object.keys(option);
    let least;
    let closestMatch;

    options.forEach(o => {
      let delta = 0;
      delta += Math.max((option[o]['height'] - parseInt(originalHeight)), (parseInt(originalHeight) - option[o]['height']));
      delta += Math.max((option[o]['width'] - parseInt(originalWidth)), (parseInt(originalWidth) - option[o]['width']));
      if (!least || delta < least) {
        least = delta;
        closestMatch = o;
      }
    });


    const oldSrc = this.selectedElement.src;
    const newSrc = option[closestMatch]['url'];
    this.selectedElement.src = newSrc;
    this.addSingletonLog(commands, 'src', oldSrc, newSrc);
  }

  selectUploadedImage(dataUrl) {
    const commands = `*Upload an image*`;

    const oldSrc = this.selectedElement.src;
    const newSrc = dataUrl;
    this.selectedElement.src = newSrc;
    this.addSingletonLog(commands, 'src', oldSrc, newSrc);
  }

// general interactions
  getColorSchemes(number?) {
    number = number || 10;
    const schemes = [];
    for (let i = number; i > 0; i--) {
      const primaryColorName = this.getRandomElement(Object.keys(this.materialColors));
      let secondaryColorName = this.getRandomElement(Object.keys(this.materialColors));
      do {
        secondaryColorName = this.getRandomElement(Object.keys(this.materialColors));
      } while (primaryColorName === secondaryColorName);

      const primaryPalette = this.materialColors[primaryColorName];
      const secondaryPalette = this.materialColors[secondaryColorName];

      const primaryColor = this.getRandomElement(primaryPalette);
      const secondaryColor = this.getRandomElement(secondaryPalette);

      schemes.push({
        primaryName: primaryColorName,
        primary: primaryColor,
        secondaryName: secondaryColorName,
        secondary: secondaryColor
      });
    }

    return schemes;
  }

  private getComputedStyle(property) {
    return window.getComputedStyle(this.selectedElement, null).getPropertyValue(property);
  }

  private getRandomElement(list: any[]) {
    if (!list) {
      return null;
    }

    if (list.length == 1) {
      return list[0];
    }

    const min = Math.ceil(0);
    const max = Math.floor(list.length - 1);
    const result = Math.floor(Math.random() * (max - min + 1)) + min;
    return list[result];
  }

  private isTextElement(element) {
    return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'p', 'span', 'a', 'li', 'small'].includes(this.selectedElement.tagName.toLowerCase());
  }

  fullStop() {
    this.recording = false;
    if (this.voice) {
      this.voice.stop();
    }
    this._describing$.next(false);
    this._selecting$.next(false);
    this._commands$.next('');
  }

  private generateNewLink(): string {
    let link = '';
    for (let i = defaultLinkLength; i > 0; i--)
      link += this.getRandomGroupChar();
    return link;
  }

  private getRandomGroupChar() {
    return groupChars[Math.floor(Math.random() * groupChars.length)];
  }

  private clearSelectedElement() {
    if (this.selectedElement) {
      this.selectedElement.classList.remove('selected');
      this.selectedElement = false;
    }
  }
}
