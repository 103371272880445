import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {AngularFirestore} from "@angular/fire/firestore";
import {AngularFireAuth} from "@angular/fire/auth";
import {take} from "rxjs/operators";
declare var gtag;

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent {

  expectation = '';

  constructor(
    private angularFirestore: AngularFirestore,
    private snackBar: MatSnackBar,
    private afAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    data.commands = data.commands ? data.commands.charAt(0).toUpperCase() + data.commands.slice(1) : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  sendFeedback() {
    this.afAuth.authState.pipe(
      take(1)
    ).subscribe(user => {
      if (user) {
        gtag('event', 'feedback-given', {
          'event_category': 'Interactions',
          'event_label': 'User gave feedback on misunderstood command'
        });
        this.angularFirestore.collection('feedback').add({
          command: this.data.commands,
          expectation: this.expectation,
          email: user.email
        });
        this.dialogRef.close();
        this.snackBar.open('Thank you for helping to improve Monolog 👍', null, {duration: 3000});
      }
    });
  }

}
