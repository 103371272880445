import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

const SHUTTERSTOCK_BASE_URL = 'https://api.shutterstock.com/v2/images/search';
const authOptions = {
  headers: new HttpHeaders({
    Authorization: 'Basic YzQ0NGYtZGY0MjktNjEyYTktZDQwNDAtNzZhNTUtYzA5Y2U6ZTkxMDQtZmUyMGQtMmI0MWUtY2RmZmYtZWZkMWUtMGFmZDU='
  })
};

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(private httpClient: HttpClient) {
  }

  getImages(query): Observable<any> {
    return this.httpClient.get(`${SHUTTERSTOCK_BASE_URL}?query=${query}`, authOptions);
  }
}
