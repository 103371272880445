import {Component, Inject, OnInit} from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {AngularFireAuth} from "@angular/fire/auth";
import {take} from "rxjs/operators";
import {TranslateService} from "../translate.service";

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss']
})
export class NameComponent {


  name;

  constructor(
    public dialogRef: MatDialogRef<NameComponent>,
  ) {
  }

  submitName() {
    this.dialogRef.close(name);
  }


}
