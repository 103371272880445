import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MatSnackBar} from "@angular/material";
import {Observable, Subscription} from "rxjs";
import {User} from "firebase";
import {AngularFireAuth} from "@angular/fire/auth";
import {TranslateService} from "../translate.service";
import {AngularFireDatabase} from "@angular/fire/database";

@Component({
  selector: 'app-command-help',
  templateUrl: './command-help.component.html',
  styleUrls: ['./command-help.component.scss']
})
export class CommandHelpComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CommandHelpComponent>) {
  }

  ngOnInit() {
  }

}
