import {Component, Inject, OnInit} from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {AngularFireAuth} from "@angular/fire/auth";
import {take} from "rxjs/operators";
import {TranslateService} from "../translate.service";

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {


  expectation = '';
  link;

  constructor(
    private angularFirestore: AngularFirestore,
    private snackBar: MatSnackBar,
    private afAuth: AngularFireAuth,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ShareComponent>,
  ) {
  }

  ngOnInit(): void {
    this.link = 'https://monolog.io/' + this.translateService.link;
  }

  copyLink(linkElement) {
    linkElement.select();
    document.execCommand('copy');
    linkElement.setSelectionRange(0, 0);
    this.snackBar.open('Link copied 👍', null, {
      duration: 2000,
      panelClass: ['sundial-snackbar']
    });
  }
}
