export class InteractionResult {
  interactionName: string;
  oldValue: any;
  newValue: any;
  modifiers: any;

  constructor(interactionName: string, oldValue?: any, newValue?: any, modifiers?: any) {
    this.interactionName = interactionName;
    this.oldValue = oldValue;
    this.newValue = newValue;
    this.modifiers = modifiers;
  }
}
