import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateService} from '../../app/translate.service';
import * as $ from 'jquery';
declare var $: any;


@Component({
  selector: 'app-gilbert',
  templateUrl: './gilbert.component.html',
  styleUrls: ['./gilbert.component.scss']
})
export class GilbertComponent implements OnInit {

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    $('*').on('mouseover mouseout', function(e) {
      $(this).toggleClass('hovering', e.type === 'mouseover');
      e.stopPropagation();
    });
    this.translateService.setPageId('aC3940');
  }

}
