import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {AngularFireDatabase} from "@angular/fire/database";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "../translate.service";

const getSiteUrl = 'https://us-central1-monolog-a6d87.cloudfunctions.net/getSite';
const templateLinks = ['bA3159', 'aC3940'];

@Component({
  selector: 'app-published-site',
  templateUrl: './published-site.component.html',
  styleUrls: ['./published-site.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishedSiteComponent implements AfterViewInit {

  link = '';

  constructor(private activatedRoute: ActivatedRoute, private angularFireDatabase: AngularFireDatabase, private renderer: Renderer2,
              private httpClient: HttpClient, private translateService: TranslateService, private cdRef: ChangeDetectorRef,
              private router: Router) {
  }

  ngAfterViewInit() {
    this.activatedRoute.paramMap.subscribe((map: ParamMap) => {
      const id = map['params']['id'];
      this.angularFireDatabase.database.ref(`/links/${id}`).once('value', snapshot => {
        if (snapshot.exists()) {
          const link = snapshot.val();

          this.angularFireDatabase.database.ref(`/sites/${link.user}/${link.site}`).once('value', siteSnapshot => {
            if (siteSnapshot.exists()) {
              const log = siteSnapshot.val()['log'];

              this.link = link.site;
              this.translateService.toggleHide();
              setTimeout(() => this.cdRef.detectChanges());
              setTimeout(() => {
                this.translateService.loadLog(log);
              }, 300);
            }
          });
        } else {
          this.router.navigate(['/']);
        }
      });
    });
  }

}
