import { Component, OnInit } from '@angular/core';
import {SignInComponent} from "../sign-in/sign-in.component";
import {MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import {TranslateService} from "../translate.service";
import {Observable, Subscription} from "rxjs";
import {User} from "firebase";
import {AngularFireDatabase} from "@angular/fire/database";
import {AngularFireAuth} from "@angular/fire/auth";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {



  constructor(private dialog: MatDialog, private translateService: TranslateService, private angularFireDatabase: AngularFireDatabase,
              private snackBar: MatSnackBar, private afAuth: AngularFireAuth) { }

  ngOnInit() {
  }

  openSignInDialog() {
    this.dialog.open(SignInComponent);
    this.translateService.fullStop();
  }

  successCallback() {
  }

  errorCallback() {
    this.snackBar.open('Sorry, something went wrong with your login. Please try again.');
  }

}
