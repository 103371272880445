import {Component, HostListener, OnInit, ViewContainerRef} from '@angular/core';
import {TranslateService} from "./translate.service";
import {filter, map, take, tap} from "rxjs/operators";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'monolog';

  selecting$ = this.translateService.selecting$;
  describing$ = this.translateService.describing$;
  hidden$ = this.translateService.hidden$;

  selectingSub;
  editing$ = this.router.events
    .pipe(
      filter(e => e instanceof NavigationEnd),
      map((event: any) => {
        const editEnabledRoutes = ['edit', 'join'];
        const result = event.url ? editEnabledRoutes.some(route => event.url.includes(route)) : false;
        return result;
      })
    );

  constructor(private translateService: TranslateService, private viewContainerRef: ViewContainerRef, private router: Router) {
  }

  ngOnInit(): void {
    this.translateService.viewContainerRef = this.viewContainerRef;
  }

  @HostListener('window:click', ['$event'])
  onClick(event) {
    this.selecting$.pipe(take(1)).subscribe(selecting => {
      if (selecting) {
        this.translateService.selectElement(event.target);
        event.preventDefault();
      }
    });
  }
}
