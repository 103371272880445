import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  MatButtonModule, MatCardModule, MatDialogModule, MatFormFieldModule,
  MatIconModule, MatInputModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {TemplatesComponent} from './templates/templates.component';
import {HomeComponent} from './home/home.component';
import {GilbertComponent} from '../templates/gilbert/gilbert.component';
import {TranslateService} from './translate.service';
import {HttpClientModule} from '@angular/common/http';
import {CommandsComponent} from './commands/commands.component';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {FeedbackSnackbarComponent} from './feedback-snackbar/feedback-snackbar.component';
import {FeedbackDialogComponent} from './feedback-dialog/feedback-dialog.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {SignInComponent} from './sign-in/sign-in.component';
import {FirebaseUIModule} from 'firebaseui-angular';
import {auth as uiAuth} from 'firebaseui';
import {auth} from 'firebase';
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireDatabase} from "@angular/fire/database";
import {AmplifyComponent} from '../templates/amplify/amplify.component';
import {ShareComponent} from './share/share.component';
import {PublishedSiteComponent} from './published-site/published-site.component';
import {ExternalSiteComponent} from './external-site/external-site.component';
import {NgxFileDropModule} from "ngx-file-drop";
import {KindleComponent} from "../templates/kindle/kindle.component";
import {AppfastComponent} from "../templates/appfast/appfast.component";
import {ZiggyComponent} from "../templates/ziggy/ziggy.component";
import {SolutionComponent} from "../templates/solution/solution.component";
import {CommandHelpComponent} from "./command-help/command-help.component";
import { JoinComponent } from './join/join.component';
import {NameComponent} from "./name/name.component";
import {QRCodeModule} from "angularx-qrcode";

declare var $: any;

const firebaseUiAuthConfig: uiAuth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    auth.GoogleAuthProvider.PROVIDER_ID
  ],
  tosUrl: 'https://monolog.io',
  privacyPolicyUrl: 'https://monolog.io',
  credentialHelper: uiAuth.CredentialHelper.ACCOUNT_CHOOSER_COM
};

@NgModule({
  declarations: [
    AppComponent,
    TemplatesComponent,
    HomeComponent,
    GilbertComponent,
    CommandsComponent,
    FeedbackSnackbarComponent,
    FeedbackDialogComponent,
    SignInComponent,
    AmplifyComponent,
    KindleComponent,
    AppfastComponent,
    ZiggyComponent,
    CommandHelpComponent,
    SolutionComponent,
    ShareComponent,
    PublishedSiteComponent,
    ExternalSiteComponent,
    JoinComponent,
    NameComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxFileDropModule,
    MatToolbarModule,
    QRCodeModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    AngularFireAuthModule,
    MatProgressSpinnerModule,
    FormsModule,
    BrowserAnimationsModule,
    DragDropModule
  ],
  entryComponents: [FeedbackSnackbarComponent, FeedbackDialogComponent, SignInComponent, ShareComponent, CommandHelpComponent, CommandsComponent, NameComponent],
  providers: [TranslateService, AngularFirestore, AngularFireDatabase],
  bootstrap: [AppComponent]
})
export class AppModule {
}
