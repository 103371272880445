import {ChangeDetectorRef, Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {AngularFireDatabase} from "@angular/fire/database";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "../translate.service";
import * as $ from 'jquery';
import {MatDialog} from "@angular/material";
import {NameComponent} from "../name/name.component";

declare var $: any;


const getSiteUrl = 'https://us-central1-monolog-a6d87.cloudfunctions.net/getSite';
const templateLinks = ['bA3159', 'aC3940'];


@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent {

  link = '';

  constructor(private activatedRoute: ActivatedRoute, private angularFireDatabase: AngularFireDatabase, private renderer: Renderer2,
              private httpClient: HttpClient, private translateService: TranslateService, private cdRef: ChangeDetectorRef,
              private router: Router, private dialog: MatDialog) {
  }

  ngAfterViewInit() {
    const name = localStorage.getItem('name');
    if (!name) {
      const nameDialog = this.dialog.open(NameComponent);
      nameDialog.afterClosed().subscribe(n => this.translateService.setDisplayName(n));
    }

    this.activatedRoute.paramMap.subscribe((map: ParamMap) => {
      const id = map['params']['id'];
      this.angularFireDatabase.database.ref(`/links/${id}`).once('value', snapshot => {
        if (snapshot.exists()) {
          const link = snapshot.val();

          this.angularFireDatabase.database.ref(`/sites/${link.user}/${link.site}`).once('value', siteSnapshot => {
            if (siteSnapshot.exists()) {
              const log = siteSnapshot.val()['log'];

              this.link = link.site;
              // this.translateService.toggleHide();
              setTimeout(() => this.cdRef.detectChanges());
              setTimeout(() => {
                $('*').on('mouseover mouseout', function (e) {
                  $(this).toggleClass('hovering', e.type === 'mouseover');
                  e.stopPropagation();
                });
                this.translateService.setPageId(this.link);
                if (name)
                  this.translateService.setDisplayName(name);
                this.translateService.loadLog(log);
              }, 300);
            }
          });
        } else {
          this.router.navigate(['/']);
        }
      });
    });
  }

}
