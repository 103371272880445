export class LogEntry {
  actor: string;
  time: string;
  command: string;
  struck: boolean;
  selector: string;
  changes: EntryChange[];
  interaction: string;


  constructor(actor: string, time: string, command: string, selector: string, property?: string, oldValue?: string, newValue?: string, changes?: EntryChange[], interaction?: string) {
    this.time = time;
    this.actor = actor;
    this.command = command;
    this.selector = selector;
    if (changes)
      this.changes = changes;
    else if (property && newValue) {
      const singletonChanges: EntryChange[] = [];
      singletonChanges.push(new EntryChange(property, oldValue, newValue));
      this.changes = singletonChanges;
    } else if (interaction) {
      this.interaction = interaction;
    }
  }
}

export class EntryChange {
  alternateSelector: string;
  property: string;
  oldValue: string | number;
  newValue: string | number;


  constructor(property: string, oldValue: string | number, newValue: string | number, alternateSelector?: string) {
    if (alternateSelector)
      this.alternateSelector = alternateSelector;
    this.property = property;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }
}
