import {Component, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '../../app/translate.service';
import * as $ from 'jquery';

declare var $: any;

const SCRIPTS = [
  'https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js',
  'js/vendor/jquery-1.11.2.min.js',
  'js/vendor/bootstrap.min.js',
  'js/plugins.js',
  'js/main.js'
];

@Component({
  selector: 'app-ziggy',
  templateUrl: './ziggy.component.html',
})
export class ZiggyComponent implements OnInit {

  constructor(private translateService: TranslateService, private renderer: Renderer2) {
  }

  ngOnInit() {
    $('*').on('mouseover mouseout', function(e) {
      $(this).toggleClass('hovering', e.type === 'mouseover');
      e.stopPropagation();
    });
    this.translateService.setPageId('se283s2');
    SCRIPTS.forEach(script => this.loadScript(script));
  }

  loadScript(url: string) {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = false;
    this.renderer.appendChild(document.body, script);
  }

}
