import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '../translate.service';
import {tap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatDialog, MatSnackBar} from '@angular/material';
import {SignInComponent} from '../sign-in/sign-in.component';
import {User} from 'firebase';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {AngularFireDatabase} from '@angular/fire/database';
import {ShareComponent} from '../share/share.component';
import {AssetService} from '../asset.service';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import Popper, {Placement} from 'popper.js';
import {CommandHelpComponent} from '../command-help/command-help.component';

function rgbShade(p, c) {
  // @ts-ignore
  var i = parseInt, r = Math.round, [a, b, c, d] = c.split(","), P = p < 0, t = P ? 0 : p * 255 ** 2,
    // @ts-ignore
    P = P ? 1 + p : 1 - p;
  // @ts-ignore
  return "rgb" + (d ? "a(" : "(") + r((P * i(a[3] == "a" ? a.slice(5) : a.slice(4)) ** 2 + t) ** 0.5) + "," + r((P * i(b) ** 2 + t) ** 0.5) + "," + r((P * i(c) ** 2 + t) ** 0.5) + (d ? "," + d : ")");
}

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ]),
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('150ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        style({transform: 'translateX(100%)'}),
        animate('150ms ease-in', style({transform: 'translateX(0%)'}))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommandsComponent implements OnInit, OnDestroy, AfterViewInit {

  selecting$ = this.translateService.selecting$;
  describing$ = this.translateService.describing$;
  options$ = this.translateService.options$.pipe(tap(option => {
    if (option && option.type === 'assetSearch')
      this.imageSearch(option.options[0]);
  }));
  hidden$ = this.translateService.hidden$;
  log$ = this.translateService.log$.pipe(tap(() => {
    setTimeout(() => {
      const log = document.getElementById('log');
      if (log) {
        log.scrollTop = log.scrollHeight;
      }
    }, 200);
  }));
  commands$ = this.translateService.command$.pipe(tap(() => {
    this.countdownVal = 60;
    this.resetTimeout();
  }));
  signedIn$: Observable<User> = this.afAuth.authState;
  files: NgxFileDropEntry[] = [];
  commandSub: Subscription;
  countdownSub: Subscription;
  searchSub: Subscription;
  countdownVal = 5;
  interval;
  targetElement;
  assetSearchResults;
  helpStep = 0;
  helpPoppers = [];
  logOpen = true;

  popperSequence = [
    {referenceElement: 'monolog-button', popperId: 'monologButtonPopper', position: 'top'},
    {referenceElement: 'cancelButton', popperId: 'promptPopper', position: 'bottom'},
  ];

  constructor(private router: Router, private angularFireDatabase: AngularFireDatabase,
              private afAuth: AngularFireAuth, private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private changeDetectorRef: ChangeDetectorRef, private dialog: MatDialog, private assetService: AssetService) {
  }

  @HostListener('document:keydown.control.z', ['$event'])
  onKeyDown(e) {
    this.translateService.toggle();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      if (this.translateService.pageId && !localStorage.getItem('shownHelp')) {
        this.startHelpTips();
        localStorage.setItem('shownHelp', 'true');
      }
    }, 2000);
  }

  startHelpTips() {
    const step = this.popperSequence[0];
    let referenceElement = document.getElementById(step.referenceElement);
    if (!referenceElement.getBoundingClientRect().top && !referenceElement.getBoundingClientRect().left) {
      referenceElement = document.getElementById('fallbackElement');
    }
    const popperElement = document.getElementById('monologButtonPopper');
    popperElement.classList.toggle('d-none');
    this.helpPoppers['monologButtonPopper'] = new Popper(referenceElement,
      popperElement,
      {
        placement: step.position as Placement
      }
    );
  }

  showCommandHelp() {
    this.dialog.open(CommandHelpComponent);
  }

  getJoinLink() {
    return "https://monolog.io/join/" + this.translateService.link;
  }

  nextHelpStep() {
    if (this.isLastTooltip()) {
      this.closeTooltip(this.popperSequence[this.popperSequence.length - 1].popperId);
      return;
    }

    this.closeTooltip(this.popperSequence[this.helpStep].popperId); //close the last one
    this.helpStep++;
    const step = this.popperSequence[this.helpStep];
    let referenceElement = document.getElementById(step.referenceElement);
    if (!referenceElement.getBoundingClientRect().top && !referenceElement.getBoundingClientRect().left) {
      referenceElement = document.getElementById('fallbackElement');
    }

    const popperElement = document.getElementById(step.popperId);
    popperElement.classList.toggle('d-none');
    this.helpPoppers[step.popperId] = new Popper(referenceElement,
      popperElement,
      {
        placement: step.position as Placement
      }
    );
  }

  isLastTooltip(): boolean {
    const lastPopperIndex = this.popperSequence.length - 1;
    return this.helpStep >= lastPopperIndex;
  }

  closeTooltip(name) {
    if (this.helpPoppers[name])
      this.helpPoppers[name].destroy();
    const graduationPopperElement = document.getElementById(name);
    graduationPopperElement.classList.toggle('d-none');
  }

  ngOnInit() {
    this.commandSub = this.translateService.command$.subscribe();
    this.resetTimeout();
  }

  ngOnDestroy(): void {
    if (this.commandSub)
      this.commandSub.unsubscribe();
    if (this.countdownSub)
      this.countdownSub.unsubscribe();
    clearInterval(this.interval);
  }


  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file

          const allowedTypes = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];

          if (allowedTypes.some(type => file.type.indexOf(type) > -1)) {
            const reader = new FileReader();
            reader.addEventListener('load', (event: any) => this.translateService.selectUploadedImage(event.target.result));
            reader.readAsDataURL(file);
          } else {
            this.snackBar.open('Only images are allowed', null, {duration: 3000});
          }

          /**
           // You could upload it like this:

           // Headers
           const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

           this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
           .subscribe(data => {
            // Sanitized logo returned from backend
          })
           **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  fileOver(event) {
  }

  fileLeave(event) {
  }

  imageSearch(query) {
    this.searchSub = this.assetService.getImages(query).subscribe(results => {
      this.assetSearchResults = results.data.map(el => el['assets']);
    });
  }

  resetTimeout() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.changeDetectorRef.detectChanges();
      if (this.countdownVal === 0) {
        clearInterval(this.interval);
        this.translateService.interpretCommands();
      } else {
        this.countdownVal--;
      }
    }, 100);
  }

  lighten(color) {
    return rgbShade(0.12, color);
  }

  darken(color) {
    return rgbShade(-0.22, color);
  }

  tooDark(color) {
    color = color.replace('rgb(', '');
    color = color.replace(')', '');
    const r = color.split(',')[0];  // extract red
    const g = color.split(',')[1];  // extract green
    const b = color.split(',')[2];  // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    if (luma < 40) {
      return true;
    }
    return false;
  }

  dismissOptions() {
    this.translateService.dismissOptions();
  }

  generateNewColorSchemes(amount) {
    this.translateService.generateNewColorSchemes(amount);
  }

  updateText(option) {
    this.translateService.updateText(option.options[0]);
    this.translateService.fullStop();
  }

  updateLink(option) {
    this.translateService.updateLink(option.options[0]);
    this.translateService.fullStop();
    this.translateService.dismissOptions();
  }

  applyColorPalette(option) {
    this.translateService.applyColorPalette(option);
  }

  selectImage(option) {
    this.translateService.selectImage(option);
  }

  selectUploadedImage(option) {
    this.translateService.selectUploadedImage(option);
  }

  stopAndResetRecording() {
    this.translateService.fullStop();
  }

  openSignInDialog() {
    this.dialog.open(SignInComponent);
    this.translateService.fullStop();
  }

  openShareDialog() {
    this.dialog.open(ShareComponent);
    this.translateService.fullStop();
  }

  signOut() {
    this.afAuth.auth.signOut();
    this.router.navigate(['/']);
    this.translateService.emptyLog();
  }

  strike(i) {
    this.translateService.toggle(i);
  }

  toggleSelectMode() {
    if (Object.keys(this.helpPoppers).length) {
      setTimeout(() => {
        this.nextHelpStep();
      }, 1000);
    }
    setTimeout(() => this.translateService.toggleSelecting(),
      100);
  }
}
