import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver, EmbeddedViewRef, Injector,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {AngularFireDatabase} from "@angular/fire/database";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "../translate.service";
import {CommandsComponent} from "../commands/commands.component";

const getSiteUrl = 'https://us-central1-monolog-a6d87.cloudfunctions.net/getSite';

const commandStyles = `
.monolog-primary-button {
  width: 180px;
  border-color: #662d91;
  border-radius: 30px;
}

.monolog-danger-button {
  width: 180px;
  border-color: darkred;
  color: darkred;
  border-radius: 30px;
}
.monolog-primary-fab.mat-fab.mat-primary {
  border: 1px solid #662d91;
  color: #662d91;
  background-color: rgba(255,255,255,0.15);
  backdrop-filter: blur(5px);
  position: fixed !important;
  bottom: 3em;
  right: 3em;
  z-index: 999;
}

.prompt {
  border-bottom: 1px solid #662d91;
  border-top: 1px solid #662d91;
  position: fixed;
  z-index: 99999;
}

.smol-spnr, .smol-spnr ::ng-deep svg {
  width: 30px !important;
  height: 30px !important;
}

.color-option {
  border: 1px solid #662d91;
  height: 250px;
  width: 420px;
  min-width: 420px;
  position: relative;

  &:hover .overlay {
    display: flex;
  }

  & .overlay {
    position: absolute;
    display: none;
    z-index: 9999;
    justify-content: center;
    align-content: center;
    top: calc(50% - 60px);
    height: 60px;
    width: 100%;
    background-color: rgba(255,255,255,0.65);
    border-top: 1px solid #662d91;
    border-bottom: 1px solid #662d91;
    backdrop-filter: blur(5px);
  }
}

.color {
  height: 100%;
  width: 100%;
  position: relative;

  & h5, & h6 {
    font-weight: 400;
  }
}

.light {
  height: 30%;
  width: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.dark {
  height: 30%;
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.drag-handle {
  position: absolute;
  top: 5px;
  left: 5px;
}

.log {
  width: 250px;
  height: 500px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
  z-index: 99;
  border: 1px solid #662d91;
  overflow: auto;
}

.actor {
  font-weight: bold;
  margin-right: 0.25em;
}

.actor::after {
  content: ':';
}

.actor.monolog {
  color: #662d91;
}

.save-alert {
  width: calc(250px - 1em);
  position: fixed;
  left: 0;
  bottom: 500px;
  z-index: 999;
  padding: 0.75rem;
}

.save-alert.alert-warning {
  color: #856404;
  border-color: #856404;
  background-color: rgba(255,255,255,0.65);
  border-left: 10px solid;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.save-alert.alert-warning a {
  color: deepskyblue;
}

mat-form-field.change-text {
  width: 450px;
  & textarea{
  resize: both;
  }
}

`;


@Component({
  selector: 'app-external-site',
  templateUrl: './external-site.component.html',
  styleUrls: ['./external-site.component.scss']
})
export class ExternalSiteComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private angularFireDatabase: AngularFireDatabase, private renderer: Renderer2,
              private httpClient: HttpClient, private router: Router, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector,
              private appRef: ApplicationRef) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((map: ParamMap) => {
      const site = `https://www.${map['params']['site'].replace('%2F, /')}`;
      this.httpClient.get(`${getSiteUrl}?url=${site}`).subscribe((resp: string) => {
        if (!resp || !resp['content']) {
          this.router.navigate(['/']);
          return;
        }
        resp['content'] = resp['content'].replace('<head>', `<head><base href="${site}"><style>${commandStyles}</style>`);
        document.write(resp['content']);
        setTimeout(() => this.loadCommandsComponent(), 4000);
      });
    });
  }

  loadCommandsComponent() {
    // Create a reference from the component
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(CommandsComponent)
      .create(this.injector);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);
  }

}
