import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MatSnackBar} from "@angular/material";
import {Observable, Subscription} from "rxjs";
import {User} from "firebase";
import {AngularFireAuth} from "@angular/fire/auth";
import {TranslateService} from "../translate.service";
import {AngularFireDatabase} from "@angular/fire/database";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  constructor(private angularFireDatabase: AngularFireDatabase, private translateService: TranslateService,
              private afAuth: AngularFireAuth, private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<SignInComponent>) {
  }

  ngOnInit() {
  }

  successCallback() {
    this.dialogRef.close();
  }

  errorCallback() {
    this.snackBar.open('Sorry, something went wrong with your login. Please try again.');
  }
}
