import {Component, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '../../app/translate.service';
import * as $ from 'jquery';

declare var $: any;

const SCRIPTS = [
  '../../assets/solution/js/jquery-3.3.1.slim.min.js',
  '../../assets/solution/js/popper.min.js',
  '../../assets/solution/js/bootstrap.min.js',
  '../../assets/solution/owl-carousel/owl.carousel.min.js',
  '../../assets/solution/js/main.js'
];

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
})
export class SolutionComponent implements OnInit {

  constructor(private translateService: TranslateService, private renderer: Renderer2) {
  }

  ngOnInit() {
    $('*').on('mouseover mouseout', function(e) {
      $(this).toggleClass('hovering', e.type === 'mouseover');
      e.stopPropagation();
    });
    this.translateService.setPageId('v38w24');
    SCRIPTS.forEach(script => this.loadScript(script));
  }

  loadScript(url: string) {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = false;
    this.renderer.appendChild(document.body, script);
  }

}
